import React from 'react';
import { Box, Divider, SxProps, Theme, Typography } from '@mui/material';
import { useDispatch, useSelector } from '../../redux/hooks';
import { selectMidwives, selectRole, selectSiteNames, selectSites } from '../../redux/slices/account';
import { clearPatientImages, clearUricAcidResults, getAllImages, getPatientsInf, getUricAcidResults, selectIsLoadingPatients, selectPatientFilter, selectPatientSorter, selectPatientsInf, selectSelectedPatient, selectTotalPatients, setSelectedPatient } from '../../redux/slices/patient';
import { Patient, PatientFilterUI, PatientSorterUI } from '../../types/patient';
import { AccountData, AccountRole, SiteData } from '../../types/account';
import { BLANK, getAccountFullName } from '../../utils/global';
import { pregnancyWeekToString, isoDateTimeToDate } from '../../utils/dateTime';
import { Column } from '../../components/Table/types';
import InfiniteTable, { MIN_BATCH_SIZE, TABLE_COL_SPACING } from '../../components/Table/InfiniteTable';
import Section from '../../components/Section';
import SearchFilter from '../../components/Filter/SearchFilter';
import { getLastSubmitText, getRowError } from './utils';

const COL_ID = 130;
const COL_ID_WIDE = 300;
const COL_SITE = 160;
const COL_DUE_DATE = 140;
const COL_WEEK_IDX = 60;
const COL_LAST_SUBMISSION = 110;
const COL_ERROR = 24;

const CORE_TABLE_WIDTH = COL_DUE_DATE + COL_WEEK_IDX + COL_LAST_SUBMISSION + COL_ERROR + 16;

const BASE_COLUMNS: Column<Patient>[] = [
  { id: 'id', label: 'Study ID', width: COL_ID, sortable: true, getValue: (patient: Patient) => patient.id },
  { id: 'site', label: 'Site', width: COL_SITE },
  { id: 'dueDate', label: 'Due Date (Ultrasound)', width: COL_DUE_DATE, sortable: true, getValue: (patient: Patient) => patient?.dueDate ? isoDateTimeToDate(patient.dueDate) : BLANK },
  { id: 'weekIdx', label: 'Week', width: COL_WEEK_IDX, getValue: (patient: Patient) => (patient?.week !== undefined && !patient.completionTimestamp) ? pregnancyWeekToString(patient.week, true) : BLANK },
  { id: 'lastSubmission', label: 'Last Submission', width: COL_LAST_SUBMISSION, getValue: (patient: Patient) => getLastSubmitText(patient) },
  { id: 'error', label: undefined, width: COL_ERROR, getValue: (patient: Patient) => getRowError(patient) },
];

const TableSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  const dispatch = useDispatch();

  const reduxFilter = useSelector(selectPatientFilter);
  const reduxSorter = useSelector(selectPatientSorter);
  const patients = useSelector(selectPatientsInf);
  const selectedPatient = useSelector(selectSelectedPatient);
  const isLoadingPatients = useSelector(selectIsLoadingPatients);
  const totalPatients = useSelector(selectTotalPatients);
  
  const role = useSelector(selectRole);
  const sites = useSelector(selectSites);
  const siteNames = useSelector(selectSiteNames);
  const midwives = useSelector(selectMidwives);

  const [columns, setColumns] = React.useState<Column<Patient>[]>([]);
  const [tableWidth, setTableWidth] = React.useState(0);

  // De-select User when unloading the User screen
  React.useEffect(() => {
    return () => {
      dispatch(setSelectedPatient(undefined));
    }
  }, [dispatch]);

  // Setup columns
  React.useEffect(() => {
    let cols = [ ...BASE_COLUMNS ];

    if (role !== AccountRole.MEDIC) {
      cols[0].width = COL_ID;
      cols[1].getValue = (item: Patient) => (item.site && siteNames[item.site]) || BLANK;

      setTableWidth(CORE_TABLE_WIDTH + COL_ID + COL_SITE + (TABLE_COL_SPACING * 5));
    }
    else {
      // Remove the 'Sites' column
      cols.splice(1, 1);
      cols[0].width = COL_ID_WIDE;

      setTableWidth(CORE_TABLE_WIDTH + COL_ID_WIDE + (TABLE_COL_SPACING * 4));
    }

    // cols[0].onClick = handleClickColumn;
    setColumns(cols);
  // }, [role, sites, reduxSorter]);
  }, [role, siteNames]);

  const getRowTextColour = ({ item, theme }: { item: Patient, theme: Theme }) => {
    if (item.dummyFlag) {
      return theme.palette.info.main;
    }
    else if (item.finished) {
      return theme.palette.text.disabled;
    }
  }

  const handleSearch = (filter: PatientFilterUI) => {
    dispatch(getPatientsInf({
      limit: MIN_BATCH_SIZE,
      offset: 0,
      filter,
    }));
  }

  const handleClickRow = ({ index }: { index: number }) => {
    const patient = selectedPatient !== patients[index] ? patients[index] : undefined;
    dispatch(setSelectedPatient(patient));

    if (!!patient) {
      dispatch(getAllImages({ patient: patient.id }));
      
      if (role === AccountRole.ADMIN) {
        dispatch(getUricAcidResults({ id: patient.id }));
      }
    }
    else {
      dispatch(clearPatientImages());
      dispatch(clearUricAcidResults());
    }
  }

  const handleClickColumn = ({ index, id }: { index: number, id: string }) => {
    // Clicked existing sort param -> change direction
    const sorter: PatientSorterUI = (reduxSorter.column === id) ? {
      column: reduxSorter.column, desc: !reduxSorter.desc,
    } : {
      column: id as any, desc: false,
    }

    dispatch(getPatientsInf({ limit: MIN_BATCH_SIZE, offset: 0, sorter }));
  }

  const loadItems = async (startIdx: number, count: number) => {
    dispatch(getPatientsInf({ offset: startIdx, limit: count }));
  }

  return (
    <Section sx={{ height: '100%', p: 0, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', overflowX: 'auto', maxWidth: tableWidth, p: 1, pl: 2, pt: 2 }}>
        <SearchFilter
          initialValues={{ ...reduxFilter }}
          filters={[
            { type: 'text', id: 'idPattern', label: 'Study ID', searchOnChange: true, width: 160 },
            { type: 'options', id: 'site', label: 'Site', options: sites, width: 175, visible: role !== AccountRole.MEDIC,
              getKey: (site: SiteData) => site.id,
              getLabel: (site: SiteData) => site.name,
              isEqual: (a: SiteData, b: SiteData) => a.id === b.id,
            },
            { type: 'options', id: 'midwife', label: 'Midwife', options: midwives, width: 240,
              getKey: (midwife: AccountData) => midwife.username,
              getLabel: (midwife: AccountData) => getAccountFullName(midwife),
              isEqual: (a: AccountData, b: AccountData) => a.username === b.username,
            },
            { type: 'boolean', id: 'includeCompleted', label: 'Finished' },
          ]}
          onSearch={handleSearch}
        />
      </Box>

      <InfiniteTable
        totalItems={totalPatients >= 0 ? totalPatients : MIN_BATCH_SIZE}
        columns={columns}
        items={patients}
        sorter={reduxSorter}
        selectedItemIdx={patients.findIndex(kit => kit === selectedPatient)}
        isLoading={isLoadingPatients}
        noResultsText='No Testkits Found'
        loadMoreItems={loadItems}
        getRowTextColour={getRowTextColour}
        onClickRow={handleClickRow}
        onSortColumn={handleClickColumn}
      />

      <Divider/>
      <Box sx={{ px: 3, py: 1 }}>
      <Typography color={theme => theme.palette.text.secondary}>Count: {totalPatients >= 0 ? totalPatients : BLANK}</Typography>
      </Box>
    </Section>
  );
}

export default TableSection;