import React from 'react';
import { Box, Divider, SxProps, Theme, Typography } from '@mui/material';
import { useDispatch, useSelector } from '../../redux/hooks';
import { selectRole, selectSiteNames, selectSites } from '../../redux/slices/account';
import { getTestkitsInf, selectIsLoadingTestkits, selectSelectedTestkit, selectTestkitFilter, selectTestkitSorter, selectTestkitsInf, selectTotalTestkits, setSelectedStockTestkit } from '../../redux/slices/testkit';
import { AccountRole, SiteData } from '../../types/account';
import { ADMIN_STATUSES, MIDWIFE_STATUSES, Testkit, TestkitStatusAPI, TestkitStatusUI, getTestkitStatusLabel, getKitStatusUILabel, TestkitFilterUI, getCompletedFilterOverride, getDispatchedFilterOverride } from '../../types/testkit';
import { BLANK } from '../../utils/global';
import { isoDateTimeToDate } from '../../utils/dateTime';
import { getTestkitLocation } from '../../utils/testkit';
import { Column, RowRendererProps } from '../../components/Table/types';
import { Row } from '../../components/Table/renderers';
import Section from '../../components/Section';
import SearchFilter from '../../components/Filter/SearchFilter';
import InfiniteTable, { MIN_BATCH_SIZE, TABLE_COL_SPACING } from '../../components/Table/InfiniteTable';

const COL_ID = 120;
const COL_STATUS = 120;
const COL_LOCATION = 210;
const COL_SWAB_EXPIRY = 110;
const COL_PAPER_EXPIRY = 110;

const TABLE_WIDTH = COL_ID + COL_STATUS + COL_LOCATION + COL_SWAB_EXPIRY + COL_PAPER_EXPIRY + (TABLE_COL_SPACING * 4) + 16;

const BASE_COLUMNS: Column<Testkit>[] = [
  { id: 'id', label: 'Study ID', width: COL_ID, sortable: true, getValue: (item: Testkit) => item.id },
  { id: 'status', label: 'Status', width: COL_STATUS, sortable: true, getValue: (item: Testkit) => getTestkitStatusLabel(item) },
  { id: 'location', label: 'Location', width: COL_LOCATION, getValue: (item: Testkit) => BLANK },
  { id: 'swabExpiry', label: 'Swab Expiry', width: COL_SWAB_EXPIRY, getValue: (item: Testkit) => item.swabExpiry ? isoDateTimeToDate(item.swabExpiry) : BLANK },
  { id: 'paperExpiry', label: 'Paper Expiry', width: COL_PAPER_EXPIRY, getValue: (item: Testkit) => item.paperExpiry ? isoDateTimeToDate(item.paperExpiry) : BLANK },
]

const TableSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  const dispatch = useDispatch();

  const reduxFilter = useSelector(selectTestkitFilter);
  const reduxSorter = useSelector(selectTestkitSorter);
  const testkits = useSelector(selectTestkitsInf);
  const selectedTestkit = useSelector(selectSelectedTestkit);
  const isLoadingTestkits = useSelector(selectIsLoadingTestkits);
  const totalTestkits = useSelector(selectTotalTestkits);

  const role = useSelector(selectRole);
  const sites = useSelector(selectSites);
  const siteNames = useSelector(selectSiteNames);

  const [columns, setColumns] = React.useState<Column<Testkit>[]>([]);

  // De-select User when unloading the User screen
  React.useEffect(() => {
    return () => {
      dispatch(setSelectedStockTestkit(undefined));
    }
  }, [dispatch]);

  // Setup columns
  React.useEffect(() => {
    const cols = [...BASE_COLUMNS];
    cols[2].getValue = (kit: Testkit) => getTestkitLocation({ kit, role, sites: siteNames });
    setColumns(cols);
  }, [role, siteNames, reduxSorter]);

  const getRowColour = ({ item, theme }: { item: Testkit, theme: Theme }) => {
    switch (item.status) {
      case TestkitStatusAPI.NEW:
        return theme.palette.kitStatus.new;
      case TestkitStatusAPI.STOCK:
        return theme.palette.kitStatus.stock;
      case TestkitStatusAPI.ALLOCATED:
        return !!item.dispatched ? theme.palette.kitStatus.dispatched : theme.palette.kitStatus.allocated;
      case TestkitStatusAPI.ISSUED:
        return theme.palette.kitStatus.issued;
      case TestkitStatusAPI.DONE:
        return theme.palette.kitStatus.archived;
      default:
        return undefined;
    }
  }

  const getRowTextColour = ({ item, theme }: { item: Testkit, theme: Theme }) => {
    if (item.dummyFlag) {
      return theme.palette.info.main;
    }
    else {
      switch (item.status) {
        case TestkitStatusAPI.ISSUED:
          return theme.palette.text.secondary;
        case TestkitStatusAPI.DONE:
          return theme.palette.text.disabled;
        default:
          return undefined;
      }
    }
  }

  const stockRowRenderer = ({ index, item, columns, selected, columnSpacing, ml, mr, style, theme, onClick }: RowRendererProps<Testkit>) => {
    const rowColour  = getRowColour({ item, theme });
    const textColour = getRowTextColour({ item, theme }) || theme.palette.text.primary;
    return (
      <Row
        onClick={onClick && (() => onClick({ index, item }))}
        style={{
          ...style,
          width: undefined,
          borderLeft: rowColour ? `${rowColour} ${ml / 2}px solid` : undefined,
          color: textColour,
          backgroundColor: selected ? theme.palette.action.selected : undefined,
        }}
        sx={{
          paddingLeft: `${ml / 2}px`,
          paddingRight: `${mr}px`,
        }}
      >
        {columns.map((column, colIndex) => {
          const ml = colIndex !== 0 ? `${columnSpacing}px` : undefined;
          const contents = column.getValue?.(item);
  
          return typeof(contents) === 'string' ? (
            <Typography key={column.id} sx={{ width: column.width, ml }}>
              {contents}
            </Typography>
          ) : (
            <Box key={column.id} sx={{ width: column.width, ml, display: 'flex', alignItems: 'center' }}>
              {contents}
            </Box>
          );
        })}
      </Row>
    )
  }
  
  const handleSearch = (values: TestkitFilterUI) => {
    dispatch(getTestkitsInf({
      limit: MIN_BATCH_SIZE,
      offset: 0,
      filter: values,
    }));
  }

  const handleClickRow = ({ index }: { index: number }) => {
    dispatch(setSelectedStockTestkit(selectedTestkit !== testkits[index] ? testkits[index] : undefined))
  }

  const handleClickColumn = ({ index, id }: { index: number, id: string }) => {
    dispatch(getTestkitsInf({
      limit: MIN_BATCH_SIZE,
      offset: 0,
      sorter: {
        column: id as any,
        desc: (reduxSorter.column === id) ? !reduxSorter.desc : false,
      }
    }));
  }

  const loadItems = async (startIdx: number, count: number) => {
    dispatch(getTestkitsInf({ offset: startIdx, limit: count }));
  }

  return (
    <Section sx={{ height: '100%', p: 0, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', overflowX: 'auto', maxWidth: TABLE_WIDTH, p: 1, pl: 2, pt: 2 }}>
        <SearchFilter
          initialValues={{ ...reduxFilter }}
          filters={[
            { type: 'text', id: 'idPattern', label: 'Study ID', searchOnChange: true, width: 160 },
            { type: 'options', id: 'site', label: 'Site', options: sites, width: 180, visible: role !== AccountRole.MEDIC,
              getKey: (site: SiteData) => site.id,
              getLabel: (site: SiteData) => site.name,
              isEqual: (a: SiteData, b: SiteData) => a.id === b.id,
            },
            { type: 'options', id: 'status', label: 'Status', options: role === AccountRole.ADMIN ? ADMIN_STATUSES : MIDWIFE_STATUSES, width: 180,
              getLabel: (status: TestkitStatusUI) => getKitStatusUILabel(status),
            },
            { type: 'boolean', id: 'incDispatched', label: 'Dispatched', visible: role === AccountRole.ADMIN, override: getDispatchedFilterOverride },
            { type: 'boolean', id: 'incFinished', label: 'Finished', override: getCompletedFilterOverride },
          ]}
          onSearch={handleSearch}
        />
      </Box>

      <InfiniteTable
        totalItems={totalTestkits >= 0 ? totalTestkits : MIN_BATCH_SIZE}
        columns={columns}
        items={testkits}
        sorter={reduxSorter}
        selectedItemIdx={testkits.findIndex(kit => kit === selectedTestkit)}
        isLoading={isLoadingTestkits}
        noResultsText='No Testkits Found'
        loadMoreItems={loadItems}
        rowRenderer={stockRowRenderer}
        onClickRow={handleClickRow}
        onSortColumn={handleClickColumn}
      />

      <Divider/>
      <Box sx={{ px: 3, py: 1 }}>
        <Typography color={theme => theme.palette.text.secondary}>Count: {totalTestkits >= 0 ? totalTestkits : BLANK}</Typography>
      </Box>
    </Section>
  );
}

export default TableSection;