import { FileRejection } from "react-dropzone";

const SI_UNITS = [ 'kB', 'MB', 'GB', 'TB', 'PT', 'EB', 'ZB', 'YB' ];
const NON_SI_UNITS = [ 'KiB', 'MiB', 'GiB', 'TiB', 'PiT', 'EiB', 'ZiB', 'YiB' ];

/**
 * @author https://stackoverflow/a/14919494
 * 
 * @param bytes Number of bytes
 * @param si True to use SI units (base 1000), False to use binary/IEC (base 1024)
 * @param dp Number of decimal places to display
 * @returns Formatted string
 */
export const readableFileSize = (bytes: number, si: boolean = true, dp: 0 | 1 | 2 = 1) => {
  const mult = si ? 1000 : 1024;

  if (Math.abs(bytes) < mult) {
    return bytes + ' B';
  }

  const units = si ? SI_UNITS : NON_SI_UNITS;

  const r = 10**dp;
  let u = -1;

  do {
    bytes /= mult;
    ++u;
  }
  while (Math.round(Math.abs(bytes) * r) / r >= mult && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

export const displayDropzoneErrors = (rejectedFiles: readonly FileRejection[]) => {
  if (rejectedFiles.length > 0) {
    console.warn(rejectedFiles);
  }
}