import React from "react";
import { Box, Typography } from "@mui/material";
import { REGEX_FLOAT_1_2, REGEX_INT_1, REGEX_INT_2, REGEX_INT_3, REGEX_INT_4 } from "../../../utils/crf";
import { CrfSection, CrfSectionProps } from "../types";
import { getInput } from "../inputs";
import Expander from "../Expander";

const UltrasoundSection = React.forwardRef(({
  crf,
  editing,
  onChangeText,
  onChangeDate,
  open,
  onClick,
}: CrfSectionProps, ref) => {
  return (
    <Expander
      ref={ref}
      id={CrfSection.Ultrasound}
      open={open}
      onClick={onClick}
    >
      {getInput({ type: 'date', name: 'ultra_scanDate', label: 'Date of Scan', value: crf.ultra_scanDate, disabled: !editing, onChange: onChangeDate })}

      <Typography variant='subtitle1' color={theme => theme.palette.text.secondary} sx={{ mt: 1 }}>Gestional Age:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 0.5, mb: 2 }}>
        {getInput({ type: 'number', width: 105, name: 'ultra_gestationalAgeWeek', label: 'Weeks', format: REGEX_INT_2, value: crf.ultra_gestationalAgeWeek, disabled: !editing, onChange: onChangeText })}
        <Typography sx={{ width: 30, textAlign: 'center' }}>+</Typography>
        {getInput({ type: 'number', width: 105, name: 'ultra_gestationalAgeDay', label: 'Days', format: REGEX_INT_1, value: crf.ultra_gestationalAgeDay, disabled: !editing, onChange: onChangeText })}
      </Box>

      {getInput({ type: 'number', name: 'ultra_estimatedFetalWeight', label: 'EFW', format: REGEX_INT_4, suffix: 'grams', value: crf.ultra_estimatedFetalWeight, disabled: !editing, onChange: onChangeText })}

      <Box sx={{ display: 'flex', flexDirection: 'row', my: 2, gap: 2 }}>
        {getInput({ type: 'number', name: 'ultra_umbilicalArteryPi', label: 'Umbilical Artery PI', format: REGEX_FLOAT_1_2, value: crf.ultra_umbilicalArteryPi, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'number', name: 'ultra_cerebralArteryPindex', label: 'MCA PI', format: REGEX_FLOAT_1_2, value: crf.ultra_cerebralArteryPindex, disabled: !editing, onChange: onChangeText })}
      </Box>
      
      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Uterine Artery PI:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, gap: 2 }}>
        {getInput({ type: 'number', width: 150, name: 'ultra_uterineArteryPiLeft', label: 'Left', format: REGEX_FLOAT_1_2, value: crf.ultra_uterineArteryPiLeft, disabled: !editing, onChange: onChangeText })}
        {getInput({ type: 'number', width: 150, name: 'ultra_uterineArteryPiRight', label: 'Right', format: REGEX_FLOAT_1_2, value: crf.ultra_uterineArteryPiRight, disabled: !editing, onChange: onChangeText })}
      </Box>
    </Expander>
  );
})

export default UltrasoundSection;