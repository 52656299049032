import React from "react";
import { GenericAbortSignal } from "axios";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectRole } from "../../redux/slices/account";
import { clearCachedPatientSearchResults, getAllImages, searchPatients, selectIsLoadingImages, selectImages, selectPatientSearchResults, selectCanLoadMoreImages } from "../../redux/slices/patient";
import { Screen, setScreenTitle } from "../../routes/screens";
import { AccountRole } from "../../types/account";
import { convertImageMetadataToUI, getImageStatusUILabel, IMAGE_STATUSES, ImageFilterUI, ImageMetadataAPI, ImageMetadataUI, ImageStatus, ImageType } from "../../types/image";
import { renderPatientOption } from "../../components/autocompleteOptions";
import ScreenBox from "../../components/ScreenBox";
import Section from "../../components/Section";
import SearchFilter from "../../components/Filter/SearchFilter";
import DialogImage from "../../components/dialogs/DialogImage";
import ImagePreview from "./ImagePreview";
import { getISO } from "../../utils/dateTime";

const SEARCH_LIMIT = 30;

const PhotosScreen = () => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const patientSearchResults = useSelector(selectPatientSearchResults);
  const loadingImages = useSelector(selectIsLoadingImages);
  const canLoadMoreImages = useSelector(selectCanLoadMoreImages);
  const images = useSelector(selectImages);

  const [image, setImage] = React.useState<ImageMetadataUI | undefined>(undefined);
  const [imageType, setImageType] = React.useState<ImageType>('raw');
  const [openImage, setOpenImage] = React.useState(false);

  React.useEffect(() => {
    setScreenTitle(Screen.PHOTOS);

    return () => {
      dispatch(clearCachedPatientSearchResults());
    }
  }, []);
  
  // Fetch Image data
  React.useEffect(() => {
    const controller = new AbortController();
    // When this screen loads -> fetch users & image data
    findPatients({ signal: controller.signal });
    dispatch(getAllImages({ limit: SEARCH_LIMIT, signal: controller.signal }));
    return () => { controller.abort() }
  }, [dispatch]);

  React.useEffect(() => {
    if (!openImage) {
      setImage(undefined);
    }
  }, [openImage]);

  const findPatients = ({ idPattern, signal }: { idPattern?: string, signal?: GenericAbortSignal }) => {
    dispatch(searchPatients({
      idPattern,
      includeCompleted: true,
      signal,
    }));
  }

  const handleInputChangeID = (e: React.SyntheticEvent<Element, Event> | null, value: string) => {
    // 'change' event -> user is typing a partial ID -> search using input as filter
    // 'click' or 'keydown' event -> user has selected/cleared ID -> clear filter
    findPatients({ idPattern: e?.type === 'change' ? value : undefined });
  }

  const handleChangeImageType = (e: React.MouseEvent<HTMLElement>, newType: any) => {
    if (!!newType) {
      setImageType(newType);
    }
  }

  const handleSearch = (values: ImageFilterUI) => {
    dispatch(getAllImages({
      patient: values.patient?.id || undefined,
      status: values.status || undefined,
      startTime: getISO(values.startTime),                                    // From the start of this day
      endTime: getISO(values.endTime?.plus({ days: 1, milliseconds: -1 })),   // To the end of this day
      limit: SEARCH_LIMIT,
      offset: 0,
    }));
  }

  const handleClickLoadMore = () => {
    dispatch(getAllImages({
      limit: SEARCH_LIMIT,
      offset: images?.length,
    }));
  }

  const handleClickImage = (image: ImageMetadataAPI) => {
    setImage(convertImageMetadataToUI(image));
    setOpenImage(true);
  }
  
  return (
    <ScreenBox sx={{ flexDirection: 'column', justifyContent: 'start', alignItems: 'center', p: 0 }}>
      <Section sx={{ display: 'flex', flexDirection: 'row', flexShrink: 0, gap: 2, m: 1, px: 2, py: 2, width: '100%', maxWidth: 1300 }}>
        <SearchFilter
          initialValues={{} as ImageFilterUI}
          filters={[
            { type: 'options', id: 'patient', label: 'Study ID', options: patientSearchResults,
              getLabel: (kit) => kit.id,
              isEqual: (a, b) => (a.id === b.id),
              onInputChange: handleInputChangeID,
              disabled: (values) => !!values.status,
              renderOption: renderPatientOption,
            },
            { type: 'options', id: 'status', label: 'Status', options: IMAGE_STATUSES, width: 260, disabled: (values) => !!values.patient,
              getLabel: (status: ImageStatus) => getImageStatusUILabel(status),
            },
            { type: 'date', id: 'startTime', label: 'From' },
            { type: 'date', id: 'endTime', label: 'To' },
          ]}
          onSearch={handleSearch}
          sx={{ mr: 'auto' }}
        />

        {role === AccountRole.ADMIN && (
          <ToggleButtonGroup
            exclusive
            color='secondary'
            value={imageType}
            onChange={handleChangeImageType}
          >
            <ToggleButton sx={{ maxHeight: 40 }} value='raw'>Raw Image</ToggleButton>
            <ToggleButton sx={{ maxHeight: 40 }} value='whiteBalance'>White Balanced</ToggleButton>
            <ToggleButton sx={{ maxHeight: 40 }} value='mask'>Mask</ToggleButton>
          </ToggleButtonGroup>
        )}
      </Section>
      
      <Box sx={{
        display: 'flex', flexDirection: 'column',
      }}>
        <Box sx={{
          display: 'flex', flexDirection: 'row',
          flexFlow: 'wrap', gap: 2, rowGap: 2,
          boxSizing: 'border-box', width: '100%',
          justifyContent: 'center',
          p: 2, pt: 0,
        }}>
          {images?.map((image) => (
            <ImagePreview key={image.id} image={image} imageType={imageType} onClick={handleClickImage}/>
          ))}
        </Box>
        
        {canLoadMoreImages && (
          <Button disabled={loadingImages} sx={{ width: 200, mb: 2, alignSelf: 'center' }} onClick={handleClickLoadMore}>Load More</Button>
        )}
      </Box>

      <DialogImage image={image} open={openImage} setOpen={setOpenImage}/>
    </ScreenBox>
  );
}

export default PhotosScreen;