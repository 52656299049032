import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Box, Button, Divider, SxProps, Theme, Typography } from '@mui/material';
import {
  AddRounded as AddIcon,
  SwapHorizRounded as TransferIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectMidwifeNames, selectRole, selectSiteNames } from '../../redux/slices/account';
import { selectDisplayedImage, selectIsLoadingImages, selectImages, selectSelectedPatient, selectUricAcidResults, setDisplayedImage } from '../../redux/slices/patient';
import { AccountRole } from '../../types/account';
import { getFinishReasonUILabel } from '../../types/patient';
import { convertImageMetadataToUI, ImageMetadataUI } from '../../types/image';
import { pregnancyWeekToString, isoDateTimeToDate } from '../../utils/dateTime';
import { getFinishedDataProps, getKitIssuedDataProps } from './utils';
import { DAYS, DAY_SIZE, DAY_SPACING } from '../../components/Calendar/utils';
import Section from '../../components/Section';
import Parameter from '../../components/Parameter';
import Calendar from '../../components/Calendar';
import DialogImage from '../../components/dialogs/DialogImage';
import DialogAddSecondID from './DialogAddSecondID';
import DialogTransferMidwife from './DialogTransferMidwife';
import DialogFinishTrial from './DialogFinishTrial';

const TITLE_WIDTH = 130;
const VALUE_WIDTH = 170;

const PARAM_SIZE = { titleWidth: TITLE_WIDTH, valueWidth: VALUE_WIDTH };

const DetailsSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const sites = useSelector(selectSiteNames);
  const midwives = useSelector(selectMidwifeNames);
  const selectedPatient = useSelector(selectSelectedPatient);
  const loadingImages = useSelector(selectIsLoadingImages);
  const images = useSelector(selectImages);
  const displayedImage = useSelector(selectDisplayedImage);
  const uricAcidResults = useSelector(selectUricAcidResults);

  const [image, setImage] = React.useState<ImageMetadataUI | undefined>(undefined);
  const [secondaryIDs, setSecondaryIDs] = React.useState<string[]>([]);

  const [openImage, setOpenImage] = React.useState(false);
  const [openAddID, setOpenAddID] = React.useState(false);
  const [openTransferMidwife, setOpenTransferMidwife] = React.useState(false);
  const [openFinishTrial, setOpenFinishTrial] = React.useState(false);

  const [startDate, setStartDate] = React.useState<DateTime | undefined>(undefined);
  const [dueDate, setDueDate] = React.useState<DateTime | undefined>(undefined);
  const [finishDate, setFinishDate] = React.useState<DateTime | undefined>(undefined);

  React.useEffect(() => {
    if (selectedPatient) {
      const kits = selectedPatient.testkits.map(kit => kit.testkit);
      kits.splice(0, 1);    // Remove primary ID from list
      setSecondaryIDs(kits);

      setStartDate(DateTime.fromISO(selectedPatient.startTimestamp));
      setDueDate(DateTime.fromISO(selectedPatient.dueDate));
      setFinishDate(selectedPatient.completionTimestamp ? DateTime.fromISO(selectedPatient.completionTimestamp) : undefined);
    }
    else {
      setSecondaryIDs([]);

      setStartDate(undefined);
      setDueDate(undefined);
      setFinishDate(undefined);
    }
  }, [selectedPatient]);

  React.useEffect(() => {
    if (!!displayedImage) {
      setImage(convertImageMetadataToUI(displayedImage));
      setOpenImage(true);
    }
  }, [displayedImage]);

  React.useEffect(() => {
    if (!openImage) {
      setImage(undefined);
      dispatch(setDisplayedImage(undefined));
    }
  }, [dispatch, openImage]);

  return (
    <Section sx={{ flexShrink: 0, pr: 0, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, mr: 3, alignItems: 'start' }}>
        <Typography variant='h5'>User Details</Typography>

        {selectedPatient?.dummyFlag && (
          <Typography variant='h5' sx={theme => ({ color: theme.palette.info.main, ml: 2, alignSelf: 'center' })}>DUMMY USER</Typography>
        )}

        {(role === AccountRole.POWER_MEDIC || role === AccountRole.MEDIC) && (
          <>
            <Button disabled={!selectedPatient} component={Link} to={`/crf/${selectedPatient?.id}`} sx={{ ml: 'auto', mr: 1 }}>View CRF</Button>
            <Button disabled={!selectedPatient || selectedPatient.finished} onClick={() => {setOpenFinishTrial(true)}}>Finish Trial</Button>
            <DialogFinishTrial open={openFinishTrial} userId={selectedPatient?.id} onClose={() => {setOpenFinishTrial(false)}}/>
          </>
        )}
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'stretch', minHeight: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', mr: 1 }}>
          {role === AccountRole.ADMIN ? (
            <Parameter title='Study ID' primaryValue={selectedPatient?.id} secondaryValue={secondaryIDs} {...PARAM_SIZE}/>
          ) : (
            <>
              <Parameter title='Study ID' primaryValue={selectedPatient?.id} secondaryValue={secondaryIDs} buttonIcon={<AddIcon/>} buttonLabel='Issue additional kit to user' buttonDisabled={!selectedPatient} onClick={() => {setOpenAddID(true)}} {...PARAM_SIZE}/>
              <DialogAddSecondID open={openAddID} primaryKit={selectedPatient} onClose={() => {setOpenAddID(false)}}/>
            </>
          )}

          {role !== AccountRole.MEDIC && (
            <Parameter title='Site' primaryValue={selectedPatient?.site && sites[selectedPatient.site]} {...PARAM_SIZE}/>
          )}

          {role === AccountRole.POWER_MEDIC ? (
            <>
              <Parameter title='Midwife' primaryValue={selectedPatient?.midwife && midwives[selectedPatient.midwife]} buttonIcon={<TransferIcon/>} buttonLabel='Transfer user to another midwife' buttonDisabled={!selectedPatient} onClick={() => {setOpenTransferMidwife(true)}} {...PARAM_SIZE}/>
              <DialogTransferMidwife open={openTransferMidwife} primaryKit={selectedPatient} onClose={() => {setOpenTransferMidwife(false)}}/>
            </>
          ) : (
            <Parameter title='Midwife' primaryValue={selectedPatient?.midwife && midwives[selectedPatient.midwife]} {...PARAM_SIZE}/>
          )}

          {/* <Divider sx={{ my: 1.5 }}/> */}
          
          <Parameter title='Due Date (Ultrasound)' primaryValue={selectedPatient?.dueDate && isoDateTimeToDate(selectedPatient.dueDate)} sx={{ mt: 2 }} {...PARAM_SIZE}/>
          <Parameter title='Current Week' primaryValue={(selectedPatient && !selectedPatient.completionTimestamp) && pregnancyWeekToString(selectedPatient.week)} {...PARAM_SIZE}/>

          <Parameter title='Kit Issued' {...getKitIssuedDataProps(selectedPatient)} {...PARAM_SIZE}/>
          <Parameter title='First Used App' primaryValue={selectedPatient?.appTimestamp && isoDateTimeToDate(selectedPatient.appTimestamp)} {...PARAM_SIZE}/>

          <Parameter title='Finish Date' {...getFinishedDataProps(selectedPatient)} {...PARAM_SIZE}/>
          <Parameter title='Reason' primaryValue={getFinishReasonUILabel(selectedPatient?.finalStatus)} {...PARAM_SIZE}/>

          {role === AccountRole.ADMIN && (
            <>
              {/* <Divider sx={{ my: 1.5 }}/> */}
              
              <Parameter title='Total UA Results' primaryValue={uricAcidResults?.results.length} sx={{ mt: 2 }} {...PARAM_SIZE}/>
              <Parameter title='UA Risk' primaryValue={uricAcidResults?.risk} {...PARAM_SIZE}/>
            </>
          )}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
          <Typography variant='h6'>Submission History</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: `${DAY_SPACING}px`, mb: 1 }}>
            {DAYS.map(day => (
              <Typography key={day} variant='caption' sx={theme => ({ width: DAY_SIZE, textAlign: 'center', color: theme.palette.text.secondary })}>{day}</Typography>
            ))}
          </Box>
          
          <Divider sx={{ mr: 3 }}/>
          <Calendar
            loading={loadingImages}
            startDate={startDate}
            dueDate={dueDate}
            finishDate={finishDate}
            images={images}
          />
          <Divider sx={{ mr: 3 }}/>
        </Box>
      </Box>

      <DialogImage image={image} showUricAcidData={true} open={openImage} setOpen={setOpenImage}/>
    </Section>
  );
}

export default DetailsSection;