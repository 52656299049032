import React from "react";
import { Autocomplete, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import TestkitAPI from "../../api/endpoints/testkit";
import { useSelector } from "../../redux/hooks";
import { selectRole, selectSites } from "../../redux/slices/account";
import { selectTestkitFilter } from "../../redux/slices/testkit";
import { DialogCloseHandler } from "../../types/dialog";
import { ADMIN_STATUSES, TestkitFilterUI, convertFilterToAPI, getCompletedFilterOverride, getDispatchedFilterOverride, getKitStatusUILabel } from "../../types/testkit";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";

const DialogDownload = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const role = useSelector(selectRole);
  const sites = useSelector(selectSites);
  const reduxFilter = useSelector(selectTestkitFilter);

  const [initialFilter, setInitialFilter] = React.useState<TestkitFilterUI>({});

  React.useEffect(() => {
    if (open) {
      setInitialFilter({
        ...reduxFilter,
      });
    }
  }, [open]);

  const handleDownload = (filter: TestkitFilterUI) => {
    TestkitAPI.downloadAllKits({
      filename: 'testkits.csv',
      limit: 1000,
      ...convertFilterToAPI(filter, role),
    })
    .then(() => { onClose?.({}, 'closeClick') })
    .catch(error => { console.error(error) });
  }

  return (
    <BaseFormDialog
      title='Download CSV data for all kits matching the filter options'
      open={open}
      onClose={onClose}
      confirmText='Download'
      confirmIcon={<DownloadIcon/>}
      initialValues={initialFilter}
      enableReinitialize
      onSubmit={handleDownload}
      formSx={{ pb: 2 }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
      }) => {
        const dispatchedOverride  = getDispatchedFilterOverride(values);
        const finishedOverride    = getCompletedFilterOverride(values);

        return (
        <>
          <TextField
            name='idPattern'
            label='Study ID'
            value={values.idPattern || ''}
            onChange={handleChange}
          />

          <Autocomplete
            disablePortal
            options={sites}
            value={values.site || null}
            onChange={(_, val) => { setFieldValue('site', val); }}
            getOptionKey={site => site.id}
            getOptionLabel={site => site.name}
            sx={{ my: 2 }}
            renderInput={params => <TextField {...params} label='Site'/>}
          />

          <Autocomplete
            disablePortal
            options={ADMIN_STATUSES}
            value={values.status || null}
            onChange={(_, val) => { setFieldValue('status', val); }}
            getOptionLabel={(status) => getKitStatusUILabel(status)}
            sx={{ mb: 1 }}
            renderInput={params => <TextField {...params} label='Status'/>}
          />

          <FormControlLabel
            disabled={dispatchedOverride !== undefined}
            label='Include dispatched'
            control={<Checkbox
              checked={dispatchedOverride ?? values.incDispatched ?? false}
              onChange={(_, val) => { setFieldValue('incDispatched', val); }}
            />}
          />

          <FormControlLabel
            disabled={finishedOverride !== undefined}
            label='Include finished'
            control={<Checkbox
              name='incFinished'
              checked={finishedOverride ?? values.incFinished ?? false}
              onChange={(_, val) => { setFieldValue('incFinished', val); }}
            />}
          />
        </>
      )}}
    </BaseFormDialog>
  );
}

export default DialogDownload;