import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useSelector } from '../redux/hooks';
import { selectIsLoggedIn, selectRole } from '../redux/slices/account';
import { AccountRole } from '../types/account';
import NavMenu from '../components/NavMenu';
import LoginScreen from '../screens/Login';
import UsersScreen from '../screens/Users';
import PhotosScreen from '../screens/Photos';
import StockScreen from '../screens/Stock';
import MidwivesScreen from '../screens/Midwives';
import CrfScreen from '../screens/CRF';
import FaqScreen from '../screens/FAQ';
import AcccountScreen from '../screens/Account';

const AuthRoute = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return isLoggedIn ? (
    <NavMenu><Outlet/></NavMenu>
  ) : (
    <Navigate to='/login'/>
  )
}

const RoleRoute = ({ checkAuth }: {checkAuth: (role?: AccountRole) => boolean}) => {
  const role = useSelector(selectRole);
  return checkAuth(role) ? <Outlet/> : <Navigate to='/users'/>
}

/**
 * TODO: add ErrorElement to handle 404s and other errors
 */

const router = createBrowserRouter([
  { index: true, element: <Navigate to='/login'/> },
  { path: '/login', element: <LoginScreen/> },

  {
    element: <AuthRoute/>,
    children: [
      { index: true, element: <Navigate to='/users'/> },
      { path: '/users', element: <UsersScreen/> },
      { path: '/stock', element: <StockScreen/> },
      { path: '/midwives', element: <MidwivesScreen/> },
      { path: '/faq', element: <FaqScreen/> },
      { path: '/account', element: <AcccountScreen/> },

      { element: <RoleRoute checkAuth={role => role === AccountRole.ADMIN}/>, children: [
        { path: '/photos', element: <PhotosScreen/> },
      ]},

      { element: <RoleRoute checkAuth={role => role === AccountRole.MEDIC || role === AccountRole.POWER_MEDIC}/>, children: [
        { path: '/crf/:id', element: <CrfScreen/> },
        { path: '/crf', element: <CrfScreen/> },
      ]},
    ],
  },
], { future: { v7_relativeSplatPath: true }})

const Router = () => {
  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }}/>
  )
}

export default Router;