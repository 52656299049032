import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useDispatch, useSelector } from '../../redux/hooks';
import { clearSelectedMidwife, getMidwifeUserCount, selectEmail, selectIsLoadingMidwives, selectMidwives, selectRole, selectSelectedMidwife, selectSiteNames, setSelectedMidwife } from '../../redux/slices/account';
import { AccountData, AccountRole } from '../../types/account';
import { BLANK, getAccountFullName } from '../../utils/global';
import { Column } from '../../components/Table/types';
import Section from '../../components/Section';
import Table from '../../components/Table';
import { isResearchMidwife } from '../../utils/midwives';

const COL_NAME = 300;
const COL_SITE = 200;
// const COL_USERS = 70;

const BASE_COLUMNS: Column<AccountData>[] = [
  { id: 'name', label: 'Name', width: COL_NAME, getValue: (midwife: AccountData) => getAccountFullName(midwife) },
  // { id: 'users', label: 'Active Users', width: COL_USERS, getValue: (midwife: AccountData) => BLANK },
]

const TableSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  const dispatch = useDispatch();

  const role = useSelector(selectRole);
  const email = useSelector(selectEmail);
  const sites = useSelector(selectSiteNames);
  const isLoadingMidwives = useSelector(selectIsLoadingMidwives);
  const allMidwives = useSelector(selectMidwives);
  const selectedMidwife = useSelector(selectSelectedMidwife);

  const [columns, setColumns] = React.useState<Column<AccountData>[]>([]);

  React.useEffect(() => {
    return () => { dispatch(clearSelectedMidwife()); }
  }, [dispatch]);

  // Setup columns
  React.useEffect(() => {
    let cols: Column<AccountData>[];

    if (role !== AccountRole.MEDIC) {
      cols = [
        BASE_COLUMNS[0],
        { id: 'site', label: 'Site', width: COL_SITE, getValue: (midwife: AccountData) => (!!midwife.site && sites[midwife.site]) || BLANK },
        // BASE_COLUMNS[1],
      ];
    }
    else {
      cols = [
        BASE_COLUMNS[0],
        // BASE_COLUMNS[1],
      ];
    }

    setColumns(cols);
  }, [role, sites]);

  const handleClickRow = ({ index }: { index: number }) => {
    let midwife = selectedMidwife !== allMidwives[index] ? allMidwives[index] : undefined;

    if (midwife) {
      dispatch(setSelectedMidwife(midwife));

      if (role === AccountRole.ADMIN || isResearchMidwife(email)) {
        dispatch(getMidwifeUserCount({ midwife }));
      }
    }
    else {
      dispatch(clearSelectedMidwife());
    }
  }

  return (
    <Section sx={{ height: '100%', p: 0, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 3, py: 2 }}>
        <Typography variant='h5'>Midwives</Typography>
      </Box>

      <Table
        columns={columns}
        items={allMidwives}
        selectedItemIdx={allMidwives.findIndex(midwife => midwife === selectedMidwife)}
        isLoading={isLoadingMidwives}
        noResultsText='No Midwives Found'
        onClickRow={handleClickRow}
      />
    </Section>
  );
}

export default TableSection;