import React from "react";
import { Screen, setScreenTitle } from "../../routes/screens";
import { useSelector } from "../../redux/hooks";
import { selectRole } from "../../redux/slices/account";
import ScreenBox from "../../components/ScreenBox";
import DetailsSection from "./DetailsSection";
import TableSection from "./TableSection";
import { AccountRole } from "../../types/account";
import { Box, useTheme } from "@mui/material";
import GraphSection, { MAX_HEIGHT, MIN_HEIGHT } from "./GraphSection";
import { useWindowDimensions } from "../../utils/hooks/useWindowDimensions";

const UsersScreen = () => {
  const theme = useTheme();
  const role = useSelector(selectRole);

  const { height } = useWindowDimensions();

  const [sizes, setSizes] = React.useState({
    infoHeight: 0,
    graphHeight: 0,
  })

  React.useEffect(() => {
    setScreenTitle(Screen.USERS);
  }, []);
  
  React.useEffect(() => {
    const graphHeight = Math.max(MIN_HEIGHT, Math.min(MAX_HEIGHT, Math.floor(height * 0.3)));
    const infoHeight = height - graphHeight - Number.parseInt(theme.spacing(3));

    setSizes({ infoHeight, graphHeight });
  }, [theme, height]);
  
  return role === AccountRole.ADMIN ? (
    // <ScreenBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: undefined, gap: 1 }}>
    <ScreenBox sx={{ height: '100vh', p: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1, overflowX: 'auto' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, height: `${sizes.infoHeight}px` }}>
          <TableSection/>
          <DetailsSection/>
        </Box>


        <GraphSection height={sizes.graphHeight}/>
      </Box>
    </ScreenBox>
  ) : (
    <ScreenBox>
      <TableSection/>
      <DetailsSection/>
    </ScreenBox>
  );
}

export default UsersScreen;