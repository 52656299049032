import React from "react";
import { Box, Typography } from "@mui/material";
import { CrfBirthData, CrfDeliveryModeData, CrfLabourData, CrfSexData, CrfSteroids, CrfSteroidsData } from "../../../types/crf";
import { REGEX_INT_1, REGEX_INT_2, REGEX_INT_3, REGEX_INT_4 } from "../../../utils/crf";
import { CrfSection, CrfSectionProps } from "../types";
import { getInput } from "../inputs";
import Expander from "../Expander";
import { isoDateTimeToLuxon } from "../../../utils/dateTime";

const BirthDataSection = React.forwardRef(({
  patient,
  crf,
  editing,
  onChangeText,
  onChangeRadio,
  onChangeDate,
  open,
  onClick,
}: CrfSectionProps, ref) => {
  const [birthOffsetText, setBirthOffsetText] = React.useState<string>('');
  const [disableSteroids, setDisableSteroids] = React.useState(true);

  React.useEffect(() => {
    if (patient?.dueDate && crf.birth_dateOfBirth) {
      const dueDate = isoDateTimeToLuxon(patient.dueDate);
      const offset  = Math.floor(dueDate.diff(crf.birth_dateOfBirth, "days").days);
      const plural  = (Math.abs(offset) === 1) ? '' : 's';

      if (offset === 0) {
        setBirthOffsetText('On their due date');
      }
      else if (offset > 0) {
        setBirthOffsetText(`${offset} day${plural} before their due date`);
      }
      else {
        setBirthOffsetText(`${Math.abs(offset)} day${plural} after their due date`);
      }
    }
    else {
      setBirthOffsetText('');
    }

    setDisableSteroids(!editing || !crf.birth_steroidDoses || (crf.birth_steroidDoses === CrfSteroids.None));
  }, [patient, crf, editing]);

  return (
    <Expander
      ref={ref}
      id={CrfSection.BirthData}
      open={open}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, mb: 2 }}>
        {getInput({ type: 'date', name: 'birth_dateOfBirth', label: 'Date of Birth', value: crf.birth_dateOfBirth, disabled: !editing, onChange: onChangeDate})}
        {!!birthOffsetText && (
          <Typography variant='body2' color={theme => theme.palette.text.secondary}>{birthOffsetText}</Typography>
        )}
      </Box>
      <Typography variant='subtitle1' color={theme => theme.palette.text.secondary}>Gestional Age:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 0.5, mb: 2 }}>
        {getInput({ type: 'number', width: 105, name: 'birth_gestationalAgeWeek', label: 'Weeks', format: REGEX_INT_2, value: crf.birth_gestationalAgeWeek, disabled: !editing, onChange: onChangeText })}
        <Typography sx={{ width: 30, textAlign: 'center' }}>+</Typography>
        {getInput({ type: 'number', width: 105, name: 'birth_gestationalAgeDay', label: 'Days', format: REGEX_INT_1, value: crf.birth_gestationalAgeDay, disabled: !editing, onChange: onChangeText })}
      </Box>
      {getInput({ type: 'number', name: 'birth_weight', label: 'Weight', format: REGEX_INT_4, suffix: 'grams', value: crf.birth_weight, disabled: !editing, onChange: onChangeText })}
      {getInput({ type: 'radio', name: 'birth_birth', options: CrfBirthData, row: true, value: crf.birth_birth, disabled: !editing, onChange: onChangeRadio, sx: { mt: 1, mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Sex:</Typography>
      {getInput({ type: 'radio', name: 'birth_sex', options: CrfSexData, row: true, value: crf.birth_sex, disabled: !editing, onChange: onChangeRadio, sx: { mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Labour:</Typography>
      {getInput({ type: 'radio', name: 'birth_labour', options: CrfLabourData, row: true, value: crf.birth_labour, disabled: !editing, onChange: onChangeRadio, sx: { mb: 3 }})}

      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Mode of Delivery:</Typography>
      {getInput({ type: 'radio', name: 'birth_deliveryMode', options: CrfDeliveryModeData, value: crf.birth_deliveryMode, disabled: !editing, onChange: onChangeRadio, sx: { mb: 3 }})}
      
      <Typography variant='h6' color={theme => theme.palette.text.secondary}>Preterm Birth:</Typography>
      <Typography variant='subtitle1' color={theme => theme.palette.text.secondary}>If delivered {'<'}35 weeks, did the mother receive steroids?</Typography>
      {getInput({ type: 'radio', name: 'birth_steroidDoses', options: CrfSteroidsData, row: true, value: crf.birth_steroidDoses, disabled: !editing, onChange: onChangeRadio, sx: { mb: 1 }})}
      <Typography variant='subtitle1' color={theme => !disableSteroids ? theme.palette.text.secondary : theme.palette.text.disabled}>How many days before birth were the steroids administrated?</Typography>
      {getInput({ type: 'number', width: 150, name: 'birth_steroidsDays', format: REGEX_INT_3, suffix: 'days', value: (!!crf.birth_steroidDoses && crf.birth_steroidDoses !== CrfSteroids.None) ? crf.birth_steroidsDays : undefined, disabled: disableSteroids, onChange: onChangeText, sx: { mt: 1 }})}
    </Expander>
  );
})

export default BirthDataSection;