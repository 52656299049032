import { TableSortConfig } from "../components/Table/types"
import { BLANK, removeUndefined } from "../utils/global"
import { AccountData, SiteData } from "./account"

export type Patient = {
  objectType: 'patient',
  dummyFlag: boolean,
  id: string,
  site: string,
  midwife: string,
  testkits: PatientTestkit[],

  dueDate: string,
  week: number,
  finished: boolean,
  finalStatus?: FinishTrialReason,
  completionTimestamp?: string,

  startTimestamp: string,
  appTimestamp?: string,
  lastImageTimestamp?: string,
  lastSuccessfulProcessingTimestamp?: string,

  uricAcidId?: string,
}

export type PatientTestkit = {
  testkit: string,
  issueTimestamp: string,
}

export type PatientUricAcidResults = {
  objectType: 'uricacid',
  id: string,
  imageTimestamp: string,
  risk: number,
  results: UricAcidData[],
}

export type UricAcidData = {
  imageId: string,
  imageTimestamp: string,
  week: number,
  uricAcidLevel: number,
  status: UricAcidStatus,
  risk: number,
  baseUricAcidData?: {
    rgbGreenUricAcid: number,
    crcbyYUricAcid: number,
    labABUricAcid: number,
  },
}

export type UricAcidStatus = 'FULL' | 'PARTIAL' | 'ERROR';

export type PatientFilterAPI = {
  idPattern?: string,
  siteId?: string,
  midwife?: string,
  includeCompleted?: boolean,
}

export type PatientFilterUI = {
  idPattern?: string | null,
  site?: SiteData | null,
  midwife?: AccountData | null,
  includeCompleted?: boolean,
}

export const convertPatientFilterToAPI = (filter: PatientFilterUI) => removeUndefined({
  idPattern: filter.idPattern || undefined,
  siteId: filter.site?.id || undefined,
  midwife: filter.midwife?.username || undefined,
  includeCompleted: filter.includeCompleted,
} as PatientFilterAPI) as PatientFilterAPI;

export type PatientSorterAPI = {
  dueDateSort?: boolean,
  descending?: boolean,
}

export type PatientSorterUI = TableSortConfig & {
  column: 'id' | 'dueDate',
}

export enum FinishTrialReason {
  Birth       = 'BIRTH',
  Miscarriage = 'MISCARRIAGE',
  Left        = 'LEFT',
}

export const convertSorterToAPI = (sorter?: PatientSorterUI) => {
  const apiSorter: PatientSorterAPI = { descending: !!sorter && sorter.desc }

  if (sorter?.column === 'dueDate') {
    apiSorter.dueDateSort = true;
  }

  return apiSorter;
}

export const getFinishReasonUILabel = (reason?: FinishTrialReason) => {
  switch (reason) {
    case FinishTrialReason.Birth:
      return 'Gave birth';
    case FinishTrialReason.Miscarriage:
      return 'Miscarriage';
    case FinishTrialReason.Left:
      return 'Left the trial';
    default:
      return BLANK;
  }
}
