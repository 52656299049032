import React from "react";
import { Box, SxProps, Theme, Typography, alpha, useTheme } from "@mui/material";
import { UploadRounded as UploadIcon } from "@mui/icons-material";
import { Accept, FileRejection, FileWithPath, useDropzone } from "react-dropzone";
import { readableFileSize } from "../utils/files";

const sxBase = (theme: Theme) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  outline: 'none',
  px: 3,
  py: 2,
  borderWidth: 4,
  borderRadius: 3,
  borderStyle: 'dashed',
  borderColor: alpha(theme.palette.secondary.main, 0.3),
  backgroundColor: alpha(theme.palette.secondary.main, 0.03),
  color: theme.palette.text.secondary,

  transition: theme.transitions.create(['border', 'background-color'], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  })
} as SxProps);

const sxFocused = (theme: Theme) => ({
  borderColor: theme.palette.secondary.main,
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
} as SxProps);

const sxAccept = (theme: Theme) => ({
  borderColor: theme.palette.success.light,
  backgroundColor: alpha(theme.palette.success.light, 0.1),
} as SxProps);

const sxReject = (theme: Theme) => ({
  borderColor: theme.palette.error.main,
  backgroundColor: alpha(theme.palette.error.main, 0.1),
} as SxProps);

// https://react-dropzone.js.org
const FileDropzone = ({
  helperText,
  showAcceptedFiles = true,
  showRejectedFiles = false,
  maxFiles = 1,
  fileTypes = { 'text/csv': [], 'application/vnd.ms-excel': ['.csv'] },
  sx,
  onFilesChanged,
}: {
  helperText: string,
  showAcceptedFiles?: boolean,
  showRejectedFiles?: boolean,
  maxFiles?: number, 
  fileTypes?: Accept,
  sx?: SxProps,
  onFilesChanged?: (acceptedFiles: readonly FileWithPath[], rejectedFiles: readonly FileRejection[]) => void
}) => {
  const theme = useTheme();

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections: rejectedFiles,
    isFocused,
    isDragAccept,
    // isDragActive,
    isDragReject,
  } = useDropzone({ accept: fileTypes, maxFiles: maxFiles || undefined });

  // React.useEffect(() => {
  //   console.log(`focused: ${isFocused}, dragAccept: ${isDragAccept}, dragActive: ${isDragActive}, dragReject: ${isDragReject}`)
  // }, [isFocused, isDragAccept, isDragActive, isDragReject]);

  // Trigger onFilesChanged if the lists of accpeted and rejected files change
  React.useEffect(() => {
    onFilesChanged?.(acceptedFiles, rejectedFiles);
  }, [acceptedFiles, rejectedFiles]);   // NOTE: do not add onFilesChanged to dependencies

  const dropzoneSx = React.useMemo(() => ({
    ...sxBase(theme),
    ...(isFocused ? sxFocused(theme) : {}),
    ...(isDragAccept ? sxAccept(theme) : {}),
    ...(isDragReject ? sxReject(theme) : {}),
    ...sx,
  }), [theme, isFocused, isDragAccept, isDragReject, sx]);

  return (
    <>
      <Box {...getRootProps()} sx={dropzoneSx}>
        <input {...getInputProps()}/>

        <UploadIcon sx={{ fontSize: '64px' }}/>
        <Typography sx={{ textAlign: 'center', mt: 1 }}>{helperText}</Typography>
      </Box>

      {showAcceptedFiles && acceptedFiles.length > 0 && (
        <Box sx={{ mt: 2 }}>
          {acceptedFiles.map(file => (
            <Box key={file.name} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', ml: 1 }}>
              <Typography>- {file.name}</Typography>
              <Typography variant='caption' sx={{ color: theme.palette.text.disabled, ml: 2 }}>{readableFileSize(file.size)}</Typography>
            </Box>
          ))}
        </Box>
      )}

      {showRejectedFiles && rejectedFiles.length > 0 && (
        <Box sx={{ mt: 2 }}>
          {rejectedFiles.map(rejection => (
            <Box key={rejection.file.name} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', ml: 1 }}>
              <Typography sx={{ color: theme.palette.error.main }}>- {rejection.file.name}</Typography>
              <Typography variant='caption' sx={{ color: theme.palette.text.disabled, ml: 2 }}>{readableFileSize(rejection.file.size)}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

export default FileDropzone;