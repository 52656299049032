import { DateTime } from "luxon";
import { Tooltip } from '@mui/material';
import {
  ErrorRounded as InvalidIcon,
  AccessTimeRounded as DelayedIcon,
  ClearRounded as RemoveIcon,
  MobileOffRounded as NoAppIcon,
  NoPhotographyRounded as NoUploadIcon,
} from '@mui/icons-material';
import { Patient } from "../../types/patient";
import { dateToString, DUE_DATE_WEEK, getDiffDays, isoDateTimeToLuxon, pregnancyWeekToString } from "../../utils/dateTime";
import { BLANK } from "../../utils/global";
import { COLOUR_ERROR, COLOUR_WARNING } from "../../theme";

const NO_UPLOAD_REMOVE_DAY        = 35;
const NO_UPLOAD_ERROR_DAY         = 14;
const NO_UPLOAD_WARNING_DAY       = 2;

const UPLOAD_DELAYED_REMOVE_DAY   = 70;
const UPLOAD_DELAYED_ERROR_DAY    = 14;
const UPLOAD_DELAYED_WARNING_DAY  = 7;

export const getKitIssuedDataProps = (patient?: Patient) => {
  if (!patient?.startTimestamp || !patient?.dueDate) {
    return { primaryValue: undefined };
  }

  const startDate = isoDateTimeToLuxon(patient.startTimestamp);
  const dueDate   = isoDateTimeToLuxon(patient.dueDate);

  const week = Math.max(0, Math.floor(DUE_DATE_WEEK - dueDate.diff(startDate, 'weeks').weeks));

  return {
    primaryValue: dateToString(startDate),
    secondaryValue: pregnancyWeekToString(week),
  }
}

export const getFinishedDataProps = (patient?: Patient) => {
  if (!patient?.completionTimestamp || !patient?.dueDate) {
    return { primaryValue: undefined };
  }

  const finishDate  = isoDateTimeToLuxon(patient.completionTimestamp);
  const dueDate     = isoDateTimeToLuxon(patient.dueDate);

  const week = Math.max(0, Math.floor(DUE_DATE_WEEK - dueDate.diff(finishDate, 'weeks').weeks));

  return {
    primaryValue: dateToString(finishDate),
    secondaryValue: pregnancyWeekToString(week),
  }
}

export const getLastSubmitText = (patient: Patient) => {
  const daysSinceSubmit = patient.lastImageTimestamp && getDiffDays(patient.lastImageTimestamp);

  if (daysSinceSubmit === undefined) {
    return BLANK;
  }
  else if (daysSinceSubmit === 0) {
    return 'Today';
  }
  else {
    return daysSinceSubmit === 1 ? '1 day ago' : `${daysSinceSubmit} days ago`;
  }
}

const getDiffDaysString = (diffDays: number) => {
  const weeksDiff    = Math.floor((diffDays - 1) / 7);

  if (weeksDiff < 1) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
  }
  else {
    return `${weeksDiff}+ week${weeksDiff > 1 ? 's' : ''}`;
  }
}

export const getRowError = (patient: Patient) => {
  if (patient.finished) {
    return;
  }

  /**
   * No app && 5+ weeks since start           -> red phone filled 
   * No app && 2+ weeks since start           -> red phone
   * No app && 2+ days since start            -> blue phone
   * 
   * Never submitted && 5+ weeks since start  -> red calendar
   * Never submitted && 2+ weeks since start  -> red calendar
   * Never submitted && 2+ days since start   -> yellow calendar
   * 
   * No submission for 10+ weeks              -> red cross
   * No submission for 2+ weeks               -> red clock
   * No submission for 1+ weeks               -> yellow clock
   * 
   * Last submission <1 week ago && invalid   -> red error
   */

  const daysSinceStart      = getDiffDays(patient.startTimestamp);
  const daysSinceSubmit     = patient.lastImageTimestamp ? getDiffDays(patient.lastImageTimestamp) : -1;
  const isLastSubmitRecent  = patient.lastImageTimestamp ? (Math.abs(DateTime.fromISO(patient.lastImageTimestamp, {zone: 'utc'}).diffNow('minutes').minutes) < 30) : false;
  
  let errorType: 'invalid' | 'delayed' | 'noApp' | 'noUpload' | 'remove' | undefined = undefined;
  let errorColour: string | undefined = undefined;
  let tooltip: string | undefined = undefined;

  if (patient.lastImageTimestamp === undefined) {
    // User hasn't installed app yet
    if (patient.appTimestamp === undefined) {
      errorType = 'noApp';
      tooltip   = `Not logged into app yet - ${getDiffDaysString(daysSinceStart)} since kit was issued`;
    }
    // User hasn't uploaded any samples yet
    else {
      errorType = 'noUpload';
      tooltip   = `No submission yet - ${getDiffDaysString(daysSinceStart)} since kit was issued`;
    }

    // User joined in the last few days -> no error
    if (daysSinceStart <= NO_UPLOAD_WARNING_DAY) {
      return;
    }
    // User started a few days ago but hasn't uploaded yet
    else if (daysSinceStart <= NO_UPLOAD_ERROR_DAY) {
      errorColour = COLOUR_WARNING;
    }
    // User started a few weeks ago
    else if (daysSinceStart <= NO_UPLOAD_REMOVE_DAY) {
      errorColour = COLOUR_ERROR;
    }
    // User started many weeks ago
    else {
      errorType   = 'remove';
      errorColour = COLOUR_ERROR;
    }
  }
  // User has missed at least 1 week's upload
  else if (daysSinceSubmit > UPLOAD_DELAYED_WARNING_DAY) {
    errorType   = 'delayed';
    errorColour = COLOUR_ERROR;
    tooltip     = `Last submission was ${getDiffDaysString(daysSinceSubmit)} ago`;

    if (daysSinceSubmit > UPLOAD_DELAYED_REMOVE_DAY) {
      errorType   = 'remove';
    }
    else if (daysSinceSubmit <= UPLOAD_DELAYED_ERROR_DAY) {
      errorColour = COLOUR_WARNING;
    }
  }
  // Last submission was not valid & more than 30 minutes ago (to give server time to process)
  else if ((patient.lastImageTimestamp !== patient.lastSuccessfulProcessingTimestamp) && !isLastSubmitRecent) {
    errorType   = 'invalid';
    errorColour = COLOUR_ERROR;
    tooltip     = 'Last submission was invalid';
  }

  if (!!errorType) {
    let icon: React.ReactNode;

    switch (errorType) {
      case 'invalid':
        icon = <InvalidIcon color='inherit'/>;
        break;
      case 'delayed':
        icon = <DelayedIcon color='inherit'/>;
        break;
      case 'remove':
        icon = <RemoveIcon color='inherit'/>;
        break;
      case 'noApp':
        icon = <NoAppIcon color='inherit'/>;
        break;
      case 'noUpload':
        icon = <NoUploadIcon color='inherit'/>;
        break;
    }

    return (
      <Tooltip title={tooltip} placement='right' disableInteractive sx={{ color: errorColour }}>
        {icon}
      </Tooltip>
    )
  }
}