import React from 'react';
import { Box, Button, IconButton, SxProps, Theme, Typography } from '@mui/material';
import { SwapHorizRounded as TransferIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from '../../redux/hooks';
import { selectEmail, selectMidwifeUserCount, selectRole, selectSelectedMidwife, selectSiteNames } from '../../redux/slices/account';
import { getAccountFullName } from '../../utils/global';
import { AccountRole } from '../../types/account';
import Section from '../../components/Section';
import Parameter from '../../components/Parameter';
import { isResearchMidwife } from '../../utils/midwives';
import DialogPromote from './DialogPromote';
import DialogDemote from './DialogDemote';
import DialogTransferAllUsers from './DialogTransferAllUsers';

const TITLE_WIDTH = 90;
const VALUE_WIDTH = 300;

const PARAM_SIZE = { titleWidth: TITLE_WIDTH, valueWidth: VALUE_WIDTH };

const DetailsSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  // const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const email = useSelector(selectEmail);
  const sites = useSelector(selectSiteNames);
  const selectedMidwife = useSelector(selectSelectedMidwife);
  const midwifeUserCount = useSelector(selectMidwifeUserCount);

  const [dialogOpenPromote, setDialogOpenPromote] = React.useState(false);
  const [dialogOpenDemote, setDialogOpenDemote] = React.useState(false);
  const [dialogOpenTransfer, setDialogOpenTransfer] = React.useState(false);

  const handleClickChangeAccess = () => {
    // Basia's access level cannot be changed by this
    if (selectedMidwife && !isResearchMidwife(selectedMidwife)) {
      // MEDIC -> able to promote
      if (selectedMidwife.role === AccountRole.MEDIC) {
        setDialogOpenPromote(true);
      }
      // POWER_MEDIC -> able to demote
      else {
        setDialogOpenDemote(true);
      }
    }
  }

  return (
    <Section sx={{ alignSelf: 'stretch', ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', mb: 2 }}>
        <Typography variant='h5'>Midwife Details</Typography>

        {(role === AccountRole.ADMIN || isResearchMidwife(email)) && (
          <Button disabled={!selectedMidwife || isResearchMidwife(selectedMidwife)} onClick={handleClickChangeAccess} sx={{ ml: 'auto' }}>
            Change Access
          </Button>
        )}
      </Box>

      <Parameter title='Name' primaryValue={selectedMidwife && getAccountFullName(selectedMidwife)} {...PARAM_SIZE}/>
      <Parameter title='Email' primaryValue={selectedMidwife?.username} {...PARAM_SIZE}/>
      {role !== AccountRole.MEDIC && (
        <Parameter title='Site' primaryValue={selectedMidwife?.site && sites[selectedMidwife?.site]} {...PARAM_SIZE}/>
      )}
      
      {(role === AccountRole.ADMIN || isResearchMidwife(email)) && (
        <>
          {role !== AccountRole.ADMIN ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
              <Typography variant='h6' sx={{ width: TITLE_WIDTH, mr: 0.5 }}>Users</Typography>
              {/* <IconButton disabled={!selectedMidwife} title='Transfer all users to a different midwife' sx={theme => ({ color: theme.palette.primary.main, my: '-3px' })} onClick={() => setDialogOpenTransfer(true)}><TransferIcon/></IconButton> */}
              <Button variant='text' disabled={!selectedMidwife} title='Transfer all users to a different midwife' onClick={() => setDialogOpenTransfer(true)}>Transfer All</Button>
              <DialogTransferAllUsers currentMidwife={selectedMidwife} open={dialogOpenTransfer} onClose={() => setDialogOpenTransfer(false)}/>
            </Box>
          ) : (
            <Typography sx={{ mt: 2 }}>Users</Typography>
          )}

          <Parameter title='Total' primaryValue={midwifeUserCount.totalUsers >= 0 ? midwifeUserCount.totalUsers : undefined} {...PARAM_SIZE}/>
          <Parameter title='Active' primaryValue={midwifeUserCount.activeUsers >= 0 ? midwifeUserCount.activeUsers : undefined} {...PARAM_SIZE}/>
          <Parameter title='Finished' primaryValue={midwifeUserCount.finishedUsers >= 0 ? midwifeUserCount.finishedUsers : undefined} {...PARAM_SIZE}/>
        </>
      )}

      <DialogPromote midwife={selectedMidwife} open={dialogOpenPromote} onClose={() => setDialogOpenPromote(false)}/>
      <DialogDemote midwife={selectedMidwife} open={dialogOpenDemote} onClose={() => setDialogOpenDemote(false)}/>
    </Section>
  );
}

export default DetailsSection;