import AuthCall from "./auth";
import { FilterPagination, ApiResponse, EndpointRequest } from "../types/base";
import { Testkit, TestkitFilterAPI, TestkitSorterAPI, TestkitStatusAPI } from "../../types/testkit";
import { APICount } from "../../types/misc";

const TestkitAPI = {
  getKitByID: async ({ id, signal }: { id: string } & EndpointRequest) => await AuthCall.get({
    endpoint: `/secure/testkit/instances/${id}`,
    signal,
  }) as ApiResponse<Testkit>,

  getAllKits: async ({ signal, ...params }: TestkitFilterAPI & TestkitSorterAPI & FilterPagination & EndpointRequest) => await AuthCall.get({
    endpoint: '/secure/testkit/instances',
    params,
    signal,
  }) as ApiResponse<Testkit[]>,

  getKitCount: async ({ signal, ...params }: TestkitFilterAPI & EndpointRequest) => await AuthCall.get({
    endpoint: '/secure/testkit/instances/count',
    params,
    signal,
  }) as ApiResponse<APICount>,

  downloadAllKits: async ({ filename, signal, ...params }: { filename?: string } & TestkitFilterAPI & TestkitSorterAPI & FilterPagination & EndpointRequest) => await AuthCall.download({
    method: 'get',
    filename,
    endpoint: '/secure/testkit/instances',
    params: { ...params, format: 'csv' },
    signal,
  }),

  updateKitData: async ({ testkit, siteId, paperExpiry, paperLot, swabExpiry, swabLot, signal }: {
    testkit: Testkit,
    siteId?: string,
    paperExpiry?: string,
    paperLot?: string,
    swabExpiry?: string,
    swabLot?: string,
  } & EndpointRequest) => await AuthCall.put({
    endpoint: '/secure/testkit/instance',
    data: {
      ...testkit,
      site: siteId || testkit.site,
      paperExpiry: paperExpiry || testkit.paperExpiry,
      paperLot: paperLot || testkit.paperLot,
      swabExpiry: swabExpiry || testkit.swabExpiry,
      swabLot: swabLot || testkit.swabLot,
    },
    signal,
  }),

  generateNewKits: async ({ count, signal }: { count: number } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secure/testkit/new/${count}`,
    signal,
  }),

  generateNewKitsSubAssembly: async ({ count, manufacturer, signal }: { count: number, manufacturer: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secure/testkit/sub-assembly/${count}`,
    params: { manufacturer },
    signal,
  }),

  uploadStockKitsCSV: async ({ file, signal }: { file: File } & EndpointRequest) => {
    const formData = new FormData();
    formData.append('file', file);

    return await AuthCall.post({
      endpoint: '/secure/testkit/stock/upload',
      data: formData,
      options: { 'Content-Type': 'multipart/form-data' },
      signal,
    })
  },

  uploadDispatchedKitsCSV: async ({ file, signal }: { file: File } & EndpointRequest) => {
    const formData = new FormData();
    formData.append('file', file);

    return await AuthCall.post({
      endpoint: '/secure/testkit/dispatched/upload',
      data: formData,
      options: { 'Content-Type': 'multipart/form-data' },
      signal,
    })
  },

  allocateKitsToMidwife: async ({ count, siteId, signal }: { count: number, siteId?: string } & EndpointRequest) => await AuthCall.post({
    endpoint: `/secure/testkit/allocate/${count}`,
    params: { siteId },
    signal,
  }),

  // transferKitToMidwife: async () => await AuthCall.post({ endpoint: '' }),

  // transferAllKitsToMidwife: async () => await AuthCall.post({ endpoint: '' }),
};

export default TestkitAPI;
