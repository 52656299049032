import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { REFRESH_DELAY, removeUndefined, validateDueDate, validateNotEmpty } from "../../utils/global";
import { useDispatch, useSelector } from "../../redux/hooks";
import { getTestkitsInf, searchTestkits, selectTestkitSearchResults } from "../../redux/slices/testkit";
import { selectRole, selectSites } from "../../redux/slices/account";
import { addUser } from "../../redux/slices/patient";
import { DialogCloseHandler } from "../../types/dialog";
import { AccountRole, SiteData } from "../../types/account";
import { Testkit, TestkitStatusUI } from "../../types/testkit";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";
import { GenericAbortSignal } from "axios";

type AddUserData = {
  site: SiteData | null,
  kit: Testkit | null,
  dueDate: DateTime<boolean> | null,
}

const DialogAddUser = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const allSites = useSelector(selectSites);
  const kitSearchResults = useSelector(selectTestkitSearchResults);

  React.useEffect(() => {
    if (open) {
      const controller = new AbortController();
      findTestkits({ signal: controller.signal });
      return () => { controller.abort() }
    }
  }, [dispatch, open]);

  const findTestkits = ({ idPattern, site, signal }: { idPattern?: string, site?: SiteData, signal?: GenericAbortSignal }) => {
    dispatch(searchTestkits({
      idPattern,
      site,
      status: TestkitStatusUI.DISPATCHED,
      signal,
    }));
  }

  const handleInputChangeID = (e: React.SyntheticEvent<Element, Event> | null, idPattern: string, site?: SiteData) => {
    // 'change' event -> user is typing a partial ID -> search using input as filter
    // 'click' or 'keydown' event -> user has selected/cleared ID -> clear filter
    findTestkits({ idPattern: e?.type === 'change' ? idPattern : undefined, site });
  }

  const handleValidate = ({ site, kit, dueDate }: AddUserData) => removeUndefined({
    site: role !== AccountRole.MEDIC ? validateNotEmpty(site?.id) : undefined,
    kit: (role === AccountRole.MEDIC || site) && validateNotEmpty(kit?.id),
    dueDate: (role === AccountRole.MEDIC || site) && validateDueDate(dueDate),
  })

  const handleSubmit = ({ site, kit, dueDate }: AddUserData) => {
    if (!kit || !dueDate) return;

    // TestkitAPI.issueKitToUser({ id: kit.id, dueDate: dueDate.toISODate() || '' })
    dispatch(addUser({ id: kit.id, dueDate }))
    .then(() => {
      onClose?.({}, 'closeClick');
        
      setTimeout(() => {
        // Repeat search with previous config after delay
        dispatch(getTestkitsInf({ refresh: true }));
      }, REFRESH_DELAY);
    })
    .catch(() => {})
  }

  return (
    <BaseFormDialog
      title='Add details for new user'
      open={open}
      onClose={onClose}
      initialValues={{ site: null, kit: null, dueDate: null } as AddUserData}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleBlur,
        setFieldValue,
      }) => (
        <>
          {role !== AccountRole.MEDIC && (
            <Autocomplete
              disablePortal
              options={allSites}
              getOptionLabel={site => site.name}
              isOptionEqualToValue={(option, value) => value.id === option.id}
              value={values.site}
              onChange={(e, val) => {
                setFieldValue('site', val);
                setFieldValue('kit', null);
                findTestkits({ site: val || undefined });
              }}
              onBlur={handleBlur}
              sx={{ mb: 2 }}
              renderInput={params => <TextField
                {...params}
                required
                name='site'
                label='Site'
                error={!!errors.site}
                helperText={errors.site}
              />}
            />
          )}

          <Autocomplete
            disablePortal
            disabled={role !== AccountRole.MEDIC && !values.site}
            value={values.kit}
            // options={role === AccountRole.MEDIC ? testkits : filterTestkits(values.site)}
            options={kitSearchResults}
            getOptionLabel={kit => kit.id}
            isOptionEqualToValue={(option, value) => value.id === option.id}
            onChange={(e, val) => { setFieldValue('kit', val) }}
            onInputChange={(e, input) => { handleInputChangeID(e, input, values.site || undefined) }}
            onBlur={handleBlur}
            sx={{ mb: 2 }}
            renderInput={params => <TextField
              {...params}
              required
              name='kit'
              label='ID'
              error={!!errors.kit}
              helperText={errors.kit}
            />}
          />

          <DatePicker
            disabled={role !== AccountRole.MEDIC && !values.site}
            label='Due Date (by Ultrasound)'
            name='dueDate'
            value={values.dueDate}
            onChange={value => setFieldValue('dueDate', value, true)}
            slotProps={{ textField: {
              required: true,
              error: !!errors.dueDate,
              helperText: errors.dueDate,
              onBlur: handleBlur,
            }}}
          />
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogAddUser;